import { useReducer, useImperativeHandle } from 'react';
import { RadioGroup } from '@headlessui/react';
import { useTranslation } from '@/helpers/translate';

function YesNo({ forwardRef = {} }) {
  const { t } = useTranslation();
  const [yesno, setYesNo] = useReducer(({ value }, answer) => {
    let newValue = answer;
    if (value && value === newValue) newValue = '';

    return { value: newValue };
  }, { value: '' });

  const getValue = () => yesno.value;

  useImperativeHandle(forwardRef, () => ({
    getValue,
  }), [yesno]);

  return (
    <div className="flex pt-3 lg:pt-4 justify-between items-center">
      <span className="text-sm text-dark-deep lg:mr-3">{t('signup:host_pro')}</span>
      <RadioGroup className="flex items-center" value={yesno.value} onChange={() => {}}>
        <RadioGroup.Option value="yes" className="py-2">
          {({ checked }) => (
            <span onClick={() => setYesNo('yes')} className={`cursor-pointer border border-white-dark4 rounded-tl-md rounded-bl-md px-3 py-2 border-r-0 ${checked ? 'bg-blue-light text-blue border-blue' : 'hover:bg-white-dark'}`}>{t('signup:yes')}</span>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="no">
          {({ checked }) => (
            <span onClick={() => setYesNo('no')} className={`cursor-pointer border border-white-dark4 rounded-tr-md rounded-br-md px-3 py-2 border-l-0 ${checked ? 'bg-blue-light text-blue border-blue' : 'hover:bg-white-dark'}`}>{t('signup:no')}</span>
          )}
        </RadioGroup.Option>
      </RadioGroup>
    </div>
  );
}

export default YesNo;
